import React from 'react'

import { Link, StaticQuery, graphql } from 'gatsby'

import styles from './style.module.scss'

import Image from 'gatsby-image'

function selectAreas(edges, all) {
    if (!all) {
        return edges.slice(0, 8)
    }
    return edges
}

const Areas = ({ forDeveloper, all }) => (
    <div className={styles.areas}>
        {all ? (
            <h1 className={styles.heading}>
                {forDeveloper ? forDeveloper : 'Featured'} areas in{' '}
                <span>Dubai</span>
            </h1>
        ) : (
            <h3 className={styles.heading}>
                {forDeveloper ? forDeveloper : 'Featured'} areas in{' '}
                <span>Dubai</span>
            </h3>
        )}

        <div className="wrapper-fluid">
            <div className="row">
                <StaticQuery
                    query={graphql`
                        {
                            allWordpressWpAreas {
                                edges {
                                    node {
                                        name
                                        slug
                                        acf {
                                            area_image {
                                                localFile {
                                                    childImageSharp {
                                                        fluid(maxWidth: 900) {
                                                            ...GatsbyImageSharpFluid_withWebp
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `}
                    render={data => {
                        return selectAreas(data.allWordpressWpAreas.edges, all)
                            .filter(
                                e =>
                                    e.node.acf &&
                                    e.node.acf.area_image &&
                                    e.node.acf.area_image.localFile
                            )
                            .map(edge => (
                                <div
                                    key={edge.node.slug}
                                    className="col-xs-12 col-sm-6 col-md-3"
                                    itemScope
                                    itemType="http://schema.org/Place"
                                >
                                    <Link
                                        to={'/area/' + edge.node.slug}
                                        className={styles.area}
                                        title={edge.node.name}
                                    >
                                        <div className={styles.imageContainer}>
                                            <Image
                                                itemProp="img"
                                                fluid={
                                                    edge.node.acf.area_image
                                                        .localFile
                                                        .childImageSharp.fluid
                                                }
                                                className={styles.image}
                                                alt={edge.node.name}
                                            />
                                        </div>

                                        <span className={styles.developer}>
                                            AREA
                                        </span>
                                        <h3
                                            itemProp="name"
                                            dangerouslySetInnerHTML={{
                                                __html: edge.node.name
                                            }}
                                        />
                                    </Link>
                                </div>
                            ))
                    }}
                />
            </div>
            {!all && (
                <Link
                    to="/areas"
                    className={styles.viewAll}
                    title="View all areas"
                >
                    View all areas
                </Link>
            )}
        </div>
    </div>
)

export default Areas
